import React from 'react'

function Button(props) {
  return (
    <a
      style={{
        display: 'inline-block',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ffd83c',
        borderRadius: '15px',
        boxShadow: 'none',
        color: '#333',
        fontSize: '1.15rem',
        fontWeight: '700',
        padding: '10px 30px',
        margin: '0 15px 15px 0'
      }}
      {...props}
    />
  )
}

export default Button
