import React from 'react'
import Button from './button'

function PodcastPromo() {
  return (
    <div>
      <h3>Jetzt den Speedrank Podcast abonnieren</h3>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        <Button href='https://podcasts.apple.com/de/podcast/speedrank-dein-seo-und-web-performance-podcast/id1483027868' title='Auf Apple Podcasts abonnieren'>
          <svg width="80" viewBox="0 0 1800 1800" xmlns="http://www.w3.org/2000/svg"><path d="M1585 1215q-39 125-123 250-129 196-257 196-49 0-140-32-86-32-151-32-61 0-142 33-81 34-132 34-152 0-301-259-147-261-147-503 0-228 113-374 113-144 284-144 72 0 177 30 104 30 138 30 45 0 143-34 102-34 173-34 119 0 213 65 52 36 104 100-79 67-114 118-65 94-65 207 0 124 69 223t158 126zm-376-1173q0 61-29 136-30 75-93 138-54 54-108 72-37 11-104 17 3-149 78-257 74-107 250-148 1 3 2.5 11t2.5 11q0 4 .5 10t.5 10z"/></svg>
          <br />
          &nbsp;Podcasts
        </Button>
        <Button href='https://open.spotify.com/show/1WHUOAThe2cGC8vtsFqzWh' title='Podcast auf Spotify abonnieren'>
          <svg width="80" viewBox="0 0 1800 1800" xmlns="http://www.w3.org/2000/svg"><path d="M1255 1210q0-32-30-51-193-115-447-115-133 0-287 34-42 9-42 52 0 20 13.5 34.5t35.5 14.5q5 0 37-8 132-27 243-27 226 0 397 103 19 11 33 11 19 0 33-13.5t14-34.5zm96-215q0-40-35-61-237-141-548-141-153 0-303 42-48 13-48 64 0 25 17.5 42.5t42.5 17.5q7 0 37-8 122-33 251-33 279 0 488 124 24 13 38 13 25 0 42.5-17.5t17.5-42.5zm108-248q0-47-40-70-126-73-293-110.5t-343-37.5q-204 0-364 47-23 7-38.5 25.5t-15.5 48.5q0 31 20.5 52t51.5 21q11 0 40-8 133-37 307-37 159 0 309.5 34t253.5 95q21 12 40 12 29 0 50.5-20.5t21.5-51.5zm205 149q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/></svg>
          <br />
          &nbsp;&nbsp;Spotify
        </Button>
        <Button href='https://www.google.com/podcasts?feed=aHR0cHM6Ly9zcGVlZHJhbmsucG9kaWdlZS5pby9mZWVkL21wMw%3D%3D' title='Podcast bei Google Podcasts abonnieren'>
          <svg width="80" viewBox="0 0 1800 1800" xmlns="http://www.w3.org/2000/svg"><path d="M896 786h725q12 67 12 128 0 217-91 387.5t-259.5 266.5-386.5 96q-157 0-299-60.5t-245-163.5-163.5-245-60.5-299 60.5-299 163.5-245 245-163.5 299-60.5q300 0 515 201l-209 201q-123-119-306-119-129 0-238.5 65t-173.5 176.5-64 243.5 64 243.5 173.5 176.5 238.5 65q87 0 160-24t120-60 82-82 51.5-87 22.5-78h-436v-264z"/></svg>
          <br />
          &nbsp;Podcasts
        </Button>
        <Button href='https://letscast.fm/podcasts/speedrank-dein-seo-und-web-performance-podcast-1f3fdb2e/feed' title='Podcast via RSS-Feed abonnieren'>
          <svg width="80" viewBox="0 0 1800 1800" xmlns="http://www.w3.org/2000/svg"><path d="M576 1344q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm512 123q2 28-17 48-18 21-47 21h-135q-25 0-43-16.5t-20-41.5q-22-229-184.5-391.5t-391.5-184.5q-25-2-41.5-20t-16.5-43v-135q0-29 21-47 17-17 43-17h5q160 13 306 80.5t259 181.5q114 113 181.5 259t80.5 306zm512 2q2 27-18 47-18 20-46 20h-143q-26 0-44.5-17.5t-19.5-42.5q-12-215-101-408.5t-231.5-336-336-231.5-408.5-102q-25-1-42.5-19.5t-17.5-43.5v-143q0-28 20-46 18-18 44-18h3q262 13 501.5 120t425.5 294q187 186 294 425.5t120 501.5z"/></svg>
          <br />
          RSS-Feed
        </Button>
      </div>
    </div>
  )
}

export default PodcastPromo
